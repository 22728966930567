exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tr-404-js": () => import("./../../../src/pages/tr/404.js" /* webpackChunkName: "component---src-pages-tr-404-js" */),
  "component---src-templates-dynamic-page-js": () => import("./../../../src/templates/dynamicPage.js" /* webpackChunkName: "component---src-templates-dynamic-page-js" */),
  "component---src-templates-news-detail-page-js": () => import("./../../../src/templates/newsDetailPage.js" /* webpackChunkName: "component---src-templates-news-detail-page-js" */),
  "component---src-templates-product-detail-page-js": () => import("./../../../src/templates/productDetailPage.js" /* webpackChunkName: "component---src-templates-product-detail-page-js" */),
  "component---src-templates-reference-detail-page-js": () => import("./../../../src/templates/referenceDetailPage.js" /* webpackChunkName: "component---src-templates-reference-detail-page-js" */)
}

